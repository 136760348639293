<template>
  <div v-if="product && addons.length">
    <vx-card no-shadow >
        <div class="text-center">
            <h3><b>Have everything you need?</b></h3>
            <p>Enhance your order with extras services</p>
        </div>
        <div class="extras-table my-4 px-2 py-2">
            <ul class="list">
                <li class="list__item header block">
                    <div class="vx-row justify-center items-center">
                        <div class="vx-col md:w-1/4 lg:w-1/3 w-full">
                            <strong>ITEM</strong>
                        </div>
                        <div class="vx-col md:w-1/12 w-full hidden md:block">
                            <strong>QTY</strong>
                        </div>
                        <div class="vx-col md:w-1/5 lg:w-1/6 w-full hidden md:block">
                            <strong>DURATION</strong>
                        </div>
                        <div class="vx-col md:w-1/6 w-full hidden md:block">
                            <strong>PRICE</strong>
                        </div>
                        <div class="vx-col md:w-1/4 lg:w-1/5 w-full">
                        </div>
                    </div>
                </li>
                <li style="border-top: 1px solid #ddd;" class="list__item block" v-for="(addon,  index) in addons" :key="index">
                    <div class="vx-row justify-center items-center">
                        <div class="vx-col md:w-1/4 lg:w-1/3 w-full mb-2 md:mb-0">
                            {{addon.name}}
                        </div>
                        <div class="vx-col md:w-1/12 w-full hidden md:block">
                            1
                        </div>
                        <div class="vx-col md:w-1/5 lg:w-1/6 w-full hidden md:block">
                            {{addon.duration | delivery_time}}
                        </div>
                        <div class="vx-col md:w-1/6 w-1/2">
                            {{order.currency_symbol}}{{addon.charge}}
                        </div>
                        <div class="vx-col md:w-1/4 lg:w-1/5 w-1/2">
                            <vs-button size="small" type="filled" @click.prevent="addService(addon)" class="m-0 ml-auto block">Add</vs-button>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <p class="secured text-center">
            <span class="lock-icon text-success mr-2" aria-hidden="true" style="width: 16px; height: 16px;">
                <svg width="14" height="16" viewBox="0 0 14 16" xmlns="http://www.w3.org/2000/svg"><path d="M12.5 7C12.9062 7 13.25 7.15625 13.5625 7.4375C13.8438 7.75 14 8.09375 14 8.5V14.5C14 14.9375 13.8438 15.2812 13.5625 15.5625C13.25 15.875 12.9062 16 12.5 16H1.5C1.0625 16 0.71875 15.875 0.4375 15.5625C0.125 15.2812 0 14.9375 0 14.5V8.5C0 8.09375 0.125 7.75 0.4375 7.4375C0.71875 7.15625 1.0625 7 1.5 7H2.25V4.75C2.25 3.90625 2.4375 3.125 2.875 2.375C3.3125 1.65625 3.875 1.09375 4.625 0.65625C5.34375 0.21875 6.125 0 7 0C7.84375 0 8.625 0.21875 9.375 0.65625C10.0938 1.09375 10.6562 1.65625 11.0938 2.375C11.5312 3.125 11.75 3.90625 11.75 4.75V7H12.5ZM8.25 12.25V10.75C8.25 10.4062 8.125 10.125 7.875 9.875C7.625 9.625 7.34375 9.5 7 9.5C6.625 9.5 6.34375 9.625 6.09375 9.875C5.84375 10.125 5.75 10.4062 5.75 10.75V12.25C5.75 12.625 5.84375 12.9062 6.09375 13.1562C6.34375 13.4062 6.625 13.5 7 13.5C7.34375 13.5 7.625 13.4062 7.875 13.1562C8.125 12.9062 8.25 12.625 8.25 12.25ZM9.25 7V4.75C9.25 4.125 9.03125 3.59375 8.59375 3.15625C8.15625 2.71875 7.625 2.5 7 2.5C6.375 2.5 5.84375 2.71875 5.40625 3.15625C4.96875 3.59375 4.75 4.125 4.75 4.75V7H9.25Z"></path></svg></span>
            <span class="mr-4">SSL Secure Payment.</span>
        </p>
        <div id="AddonCartSummary">
            <div class="mt-5" v-if="cartAddons.length">
                <h4 class="font-bold text-center">Your cart summary</h4>
                <div class="cart-table my-4 px-2 py-2">
                    <ul class="list">
                        <li class="list__item header block">
                            <div class="vx-row items-center">
                                <div class="vx-col md:w-1/4 lg:w-1/3 w-full">
                                    <strong>ITEM</strong>
                                </div>
                                <div class="vx-col md:w-1/5 lg:w-1/6 w-full hidden md:block">
                                    <strong>DURATION</strong>
                                </div>
                                <div class="vx-col md:w-1/6 w-full hidden md:block">
                                    <strong>PRICE</strong>
                                </div>
                                <div class="vx-col md:w-1/4 lg:w-1/5 w-full">
                                </div>
                            </div>
                        </li>
                        <li style="border-top: 1px solid #ddd;" class="list__item block" v-for="(addon,  index) in cartAddons" :key="index">
                            <div class="vx-row items-center">
                                <div class="vx-col md:w-1/4 lg:w-1/3 w-full mb-2 md:mb-0">
                                    {{addon.name}}
                                </div>
                                <div class="vx-col md:w-1/5 lg:w-1/6 w-full hidden md:block">
                                    {{addon.duration | delivery_time}}
                                </div>
                                <div class="vx-col md:w-1/6 w-1/2">
                                    {{order.currency_symbol}}{{addon.charge}}
                                </div>
                                <div class="vx-col md:w-1/4 lg:w-1/5 w-1/2">
                                    <vs-button size="small" type="filled" color="warning" @click.prevent="removeService(addon.id)" class="m-0 ml-auto block">X</vs-button>
                                </div>
                            </div>
                        </li>
                        <li style="border-top: 2px solid #ddd;" class="mt-4 list__item block">
                            <div class="vx-row items-center">
                                <div class="vx-col md:w-1/4 lg:w-1/3 w-full mb-2 md:mb-0">
                                    <strong>Total </strong>
                                </div>
                                <div class="vx-col md:w-1/5 lg:w-1/6 w-full hidden md:block">
                                    <strong>{{totalDuration | delivery_time}} </strong>
                                </div>
                                <div class="vx-col md:w-1/6 w-1/2">
                                    <strong>{{order.currency_symbol}}{{totalPrice }} </strong>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div>
                    <vs-button type="filled" @click.prevent="saveCart()" class="m-0 mr-auto block">Checkout</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
  </div>

</template>

<script>
export default {
    data(){
        return{
            cartAddons:[]
        }
    },
    computed:{
        order(){
            return this.$store.state.orderList.order
        },
        product(){
            return this.order.product
        },
        addons(){
            return this.product.addons
        },
        totalDuration(){
            return this.cartAddons.reduce((acc, curItem) => {
                return acc + curItem.duration;
            }, 0);
        },
        totalPrice(){
            return this.cartAddons.reduce((acc, curItem) => {
                return acc + curItem.charge;
            }, 0);
        }
    },
    methods:{
        addService(addon){
            const exists = this.cartAddons.some(item => item.id === addon.id);
            if (!exists) {
                this.cartAddons.push(addon)
            }
            this.scrollToView('AddonCartSummary')
        },
        removeService(itemId){
            const itemIndex = this.cartAddons.findIndex((item) => item.id == itemId)
            this.cartAddons.splice(itemIndex, 1)
        },
        saveCart(){
            let formData = new FormData()
            formData.append('data', JSON.stringify({items: this.cartAddons.map(i => i.id)}))
            this.$vs.loading({color: "#444", type: "sound"})
            this.$http.post(`/orders/${this.order.id}/update-addon-cart`, formData)
            .then(response => {
                this.$vs.loading.close()
                if(response.data.success){
                    this.$vs.loading({color: "#444", type: "sound"})
                    location = this.absoluteUrl(`/projects/${this.order.id}/upgrade`)
                }
            })
        }

    }
}
</script>

<style>

.extras-table {
    background-color: #f5f5f5;
    border: 4px;
}
</style>