<template>
  <div v-if="delivery">
     <vx-card no-shadow class="mb-4">
      <div class="mb-4">
        <div v-html="delivery.message"></div>
        <p class="text-sm" style="color:#ccc;">Date: {{delivery.created_at | date_time}}</p>
      </div>
      <div>
        <delivery-files v-if="delivery.files && delivery.files.length" :files="delivery.files" />
      </div>
      <div class="vx-row justify-center">
        <div class="vx-col w-full mt-3">
          <ul class="list my-2">
            <li class="list__item" v-for="(comment, index) in delivery.comments" :key="index">
              <div class="w-full">
                <span class="px-3 py-2 inline-flex rounded" style="background-color:#f8f8f8;">
                  <div class="pt-2">
                    <feather-icon icon="ArrowUpIcon" class="w-5 h-5 text-warning mr-1"></feather-icon>
                  </div>
                  <div>
                    <div class="mb-1" v-html="preText(comment.content)"></div>
                    <div class="date-font-size">{{comment.created_at | date_time}}</div>
                    <div class="text-right">
                      <a class="ml-2 text-sm" href="javascrip:void(0)" @click.prevent="initEditComment(comment)">Edit</a>
                      <a class="ml-2 text-sm text-danger" href="javascrip:void(0)" @click.prevent="confirmDeleteComment(comment.id)">Delete</a>
                      <div v-if="comment.replyer_id" class="pr-4 pt-4">
                        <h6 class="font-semibold text-sm italic">Response</h6>
                        <div class="mb-1" v-html="preText(comment.reply)"></div>
                        <small class="date-font-size">{{comment.replied_at | date_time}}</small>
                      </div>
                    </div>
                  </div>
               </span>
              </div>
            </li>
          </ul>
          <div class="mt-2 flex items-baseline py-2 bg-white">
            <vs-input class="flex-1" placeholder="Write your comment" v-model="newComment" @keyup.enter="createComment()" />
            <vs-button class="bg-primary-gradient ml-2 flex items-center" type="filled" @click="createComment()"> <feather-icon v-if="isSmallerScreen" icon="SendIcon" class="" svgClasses="pt-1 w-4 h-4"> </feather-icon> {{isSmallerScreen ? '': 'Send'}}</vs-button>
          </div>
        </div>
      </div>
    </vx-card>
    <vs-popup class="holamundo" @close="initializeAll" title="Update Comment" :active.sync="popupEditComment">
        <div class="mt-1">
          <vs-textarea label="Write your comment" v-model="onEdit.content" rows="1" />
        </div>
          <vs-button class="ml-auto mt-2" @click="updateComment()" size="small">Update</vs-button>
      </vs-popup>
  </div>
</template>

<script>
import DeliveryFiles from "./DeliveryFiles.vue"

export default {
   props: {
    delivery : { 
      type: Object,
      required: true,
      default: null
    }
  },
  components: {
    DeliveryFiles
  },
  data() {
    return {
      showDelivery: false,
      newComment: "",
      popupEditComment:false,
      onEdit: {}
    }
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    },
  },
  methods: {
    initEditComment(item){
      this.onEdit = {
        id: item.id,
        content: item.content,
      }
      this.popupEditComment = true
    },
    updateComment(){
      if(!this.onEdit.content){this.alertError('Field is empty'); return}
      const formData = new FormData()
      formData.append('data', JSON.stringify({content: this.onEdit.content}))
      this.resquestProcessor(formData,`${this.onEdit.id}/update`)
    },
    createComment(){
      if(!this.newComment){this.alertError('Field is empty'); return}
      let formData = new FormData()
      formData.append('data', JSON.stringify({"content": this.newComment, "delivery_id": this.delivery.id}))
      this.resquestProcessor(formData, 'create')
    },
    resquestProcessor(formData, action){
      this.$vs.loading({color: "#444", type: "sound"})
      this.$http.post(`/delivery-comments/${action}`, formData )
      .then(response => {
        this.$vs.loading.close()
        if(response.data.success){
          this.$store.commit("orderList/UPDATE_DELIVERY", response.data.data);
          this.initializeAll()
        }
     })
    },
    confirmDeleteComment(itemId) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: 'Are you sure?',
        text: 'You are about to delete a comment.' ,
        acceptText: "Proceed",
        accept: () => this.acceptDelete(itemId)
      })
    },
    acceptDelete(itemId) {
      if(itemId < 1){return}
      this.$http.delete(`/delivery-comments/${itemId}/delete`)
      .then(response => {
        if(response.data.success){
          this.$store.commit("orderList/UPDATE_DELIVERY", response.data.data)
          this.initializeAll()
        }
      })
    },
    initializeAll(){
      this.popupEditComment = false
      this.onEdit = {}
      this.newComment = ""
      this.$validator.reset()
    }
  }
}
</script>

<style scoped>

</style>
