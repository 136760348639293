<template>
  <div>
    <vs-alert color="warning" title="Delivery Items" :active.sync="showDelivery">
      <span>No Delivery to show for this order. </span>
    </vs-alert>
    <div v-if="deliveries.length">
      <div no-shadow class="mb-4" v-for="(delivery, index) in deliveries" :key="index">
        <deliveries :delivery="delivery" />
      </div>
    </div>
  </div>
</template>

<script>

import Deliveries from "./components/Deliveries.vue"

export default {
  components: {
    Deliveries
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    deliveries(){
      return this.order.deliveries
    },
    showDelivery(){
      return this.deliveries.length < 1
    }
  },
}
</script>
