
<template>
    <div>
        <div class="vx-row justify-left">
            <div class="vx-col w-full sm:w-1/3" v-if="imageVideoFiles.length">
              <swiper :options="swiperOption" :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl">
                <swiper-slide v-for="(slide, imageIndex) in imageVideoFiles" :key="imageIndex">
                  <img @click="lightboxIndex = imageIndex" class="responsive" :src="absoluteUrl(slide.path)" :alt="slide.name">
                </swiper-slide>
                <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
                <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
                <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
              </swiper>
            </div>
            <div class="vx-col w-full sm:w-2/3">
                <vs-list>
                    <vs-list-header icon-pack="feather" icon="icon-image" title="Delivered files">
                    </vs-list-header>
                    <ul class="list">
                        <li class="list__item py-1" v-for="file in files" :key="file.id">
                            <feather-icon icon="DownloadIcon" :svgClasses="'icon-config'" class="w-4 h-4 mr-1"></feather-icon>
                            <a :href="absoluteUrl(`/download?file_path=${file.path}`)" :download="file.name" class="text-success downloadable flex text-sm">
                            <span class="text-success">{{file.name | truncate(30)}} {{file.extension}}</span> <span class="text-dark"> ({{file.size}})</span>
                          </a>
                        </li>
                    </ul>
                </vs-list>
            </div>
        </div>
        <CoolLightBox
          :items="lightboxFiles"
          :index="lightboxIndex"
          :useZoomBar="true"
          :fullScreen="true"
          @close="lightboxIndex = null">
        </CoolLightBox>
    </div>
</template>

<script>
import 'swiper/dist/css/swiper.min.css'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import CoolLightBox from 'vue-cool-lightbox'


export default {
    name: "delivery-files",
    components: {
        swiper,
        swiperSlide,
        CoolLightBox
    },
    props: {
        files: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
          lightboxIndex: null,
          swiperOption: {
            spaceBetween: 30,
            effect: 'slide',
            autoHeight: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
          },
        }
      },
    computed: {
        lightboxFiles(){
            return this.files.map(file => {
                if(file.is_video){
                    return { title: file.name,
                      thumb: this.absoluteUrl(file.path),
                      mediaType: 'video',
                      src: this.absoluteUrl(file.video_path)
                    }
                }else{
                  return { title: file.name,
                      mediaType: 'image',
                      src: this.absoluteUrl(file.path)
                   }
                }
            })
        },
        imageVideoFiles(){
          return this.files.filter((file) => this.isImageFile(file.extension) || file.is_video)
        },
    }
}
</script>

<style scoped>

.cool-lightbox{
  z-index: 99999999999 !important;
}
.swiper-slide .subtitle {
    font-size: 21px;
    position: absolute;
    top: 5px;
    left: 10px;
}
.downloadable{
  font-size: 12px;
}
.icon-config{
  width:12px !important;
  height: 12px !important;
}

.swiper-button-next, .swiper-button-prev {
    position: absolute;
    top: 60%;
    width: 22px;
    height: 22px;
    z-index: 10;
    border-radius: 50%;
    font-size: 3px;
    background-color: rgba(0, 0, 0, 0.64);
    background-size: 18px 18px;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}
</style>
