<template>
  <vx-card no-shadow >
    <div class="md:flex justify-between items-center">
      <div class="order-details inline-block">
        <h4 class="">Order: #{{order.number}} <a :href="absoluteUrl(`/${cart.product.slug}`)" class="cursor-pointer" target="_blank"><sub>view product</sub> </a>
        </h4>
        <div class="text-sm mb-3" >Date: {{order.created_at | date_time}}</div>
        <div class="mb-3">
          <ul class="leftx sm:flex items-center">
            <li class="mr-8">
              <span>Duration:</span> <span>{{order.delivery_time | delivery_time}}</span>
            </li>
            <li class="mr-10" v-if="cart.coupon">
              <span>Discount:</span> <span>{{cart.coupon.percent}}%</span>
            </li>
            <li class="mr-10">
              <span>Total Amount:</span> <span>{{order.currency_symbol}}{{order.amount | money_format}}</span>
            </li>
            <li class="mr-10">
              <span>Delivery date:</span> <span>{{ order.delivery_time | deliveryDate(order.created_at)}}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="status-holder">
        <p class="mb-2">Status <strong class="" v-html="$store.getters['orderStatus'](order.status)"></strong></p>
      </div>
    </div>
    <vs-divider/>

    <div class="flex justify-between">
      <span class="font-bold">for {{cart.product.name }} </span>
    </div>
    <vs-divider/>
    <div class="">
      <h6 class="mb-3">Features</h6>
      <div>
        <h6><span class="hidden md:inline"> Package:</span> <span class="text-success">{{orderPackage.name}} - {{order.currency_symbol}}{{orderPackage.price}}</span></h6>
        <feature-list />
      </div>
    </div>
    <vs-divider/>
    <div class="flex flex-wrap items-center justify-end">
      <vs-button class="ml-4 mt-2" type="border" v-if="shouldMarkCompleted" size="small" @click.prevent="confirmMarkCompleted" color="success">Mark Order As Completed</vs-button>
    </div>
  </vx-card>
</template>

<script>

import FeatureList from "./components/FeatureList.vue"

export default {
  components: {
    FeatureList,
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    cart(){
      return this.order.cart
    },
    orderPackage(){
      return this.cart.package
    },
    shouldMarkCompleted(){
      return this.order.status === 'delivered'
    },
  },
  methods: {
    confirmMarkCompleted() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: 'Are you sure?',
        text: 'You are about to update this order to completed.' ,
        accept: this.markAsCompleted,
        acceptText: "Proceed",
      })
    },
    markAsCompleted() {
      const formData = new FormData()
      this.$vs.loading({color: "#444", type: "sound"})
      this.$http.post(`/orders/${this.order.id}/mark-completed`, formData)
      .then(response => {
        this.$vs.loading.close()
        if(response.data.success){
          this.$store.dispatch("orderList/fetchOrder", this.order.id)
        }
      })
    }
  },
}

</script>

<style type="text/css" scoped>

body,.text-sm{
    font-size: 12px;
}

sub{
  font-size: 12px;
}

@media(min-width: 580px){
  .status-holder{
    float:right;
    display:inline-block;
  }
}

@media(max-width: 580px){
  .order-details{
    display: block;
  }
  .status-holder{
    display:block;
  }
}
.status-holder:first-child{
    font-size: 34px;
    color:#444;
    display: inline-block;
}
table thead tr{
    border-top:2px solid #ededed !important;
    background: #c2c6dc30 !important;
}

.table-responsive {
  display: block !important;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

  @media (max-width: 767.98px) {
    .table-responsive-md {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-md > .table-bordered {
      border: 0;
    }
  }

  @media (max-width: 991.98px) {
    .table-responsive-lg {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-lg > .table-bordered {
      border: 0;
    }
  }
.icon-config{
  width:12px !important;
  height: 12px !important;
}

</style>
