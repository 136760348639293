<template>
  <div id="order-view">
    <div v-if="!isLoading">
      <vs-alert color="success" class="mb-4" title="Your order is delivered!" v-if="order.status === 'delivered'">
        <span class="text-sm mr-4">Please you are expected to access your order and make comment(s) where neccessary. If order is satisfied, mark order as completed or it will update automatically after 48 hours </span>
        <vs-button class="mt-2" type="border" size="small" @click.prevent="confirmMarkCompleted" color="success">Mark Order Completed Now</vs-button>
      </vs-alert>
      <vs-alert color="warning" class="mb-3" title="Order Satisfied?" :active.sync="isNotifyReviewal" >
          <span class="mr-4">Write a brief review and rate this order. Your feedback means a lot to us. Thanks.
          <vs-button class="mt-2" type="border" size="small" @click.prevent="popupAddReview = true" color="warning">Rate Now!</vs-button> </span>
      </vs-alert>
      <vs-alert v-if="order.status == 'draft'" color="warning" class="mb-4" title="Saved as draft!">
          <span class="text-sm">This order was saved as draft. You can proceed to make payment for the order to be processed.</span>
          <a class="ml-4 mt-2 text-success" target="_blank"  :href="absoluteUrl(`/projects/${order.id}/pricing`)">Click to pay now</a>
      </vs-alert>
      <div class="relative mb-5">
        <span v-if="countMessageNotifs" class="message-note-bell feather-icon-badge bg-warning text-white h-5 w-5 absolute rounded-full text-xs flex items-center justify-center">{{countMessageNotifs}}</span>
        <vs-tabs :value="activeTab" :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">
          <!-- GENERAL -->
          <vs-tab icon-pack="feather" icon="icon-package" :label="!isSmallerScreen ? 'Overview' : ''">
          <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
              <order-overview class="mb-3" />
              <order-delivered-files class="mb-3" />
              <order-review class="mb-3"/>
          </div>
          </vs-tab>
          <vs-tab icon-pack="feather" icon="icon-book" :label="!isSmallerScreen ? 'Requirements' : ''">
              <div class="tab-info md:ml-4 md:mt-0 mt-4 ml-0">
                <order-requirements  class="mb-3" />
              </div>
          </vs-tab>
          <vs-tab icon-pack="feather" icon="icon-mail" :label="!isSmallerScreen ? 'Messages' : ''">
              <div class="tab-info md:ml-4 md:mt-0 mt-4 ml-0">
                <order-messages />
              </div>
          </vs-tab>
        </vs-tabs>
      </div>
      <div>
        <order-extra-service
          class="mb-3"
          v-if="['pending','delivered','completed'].includes(order.status)"
          />
      </div>
      <vs-popup v-if="isNotifyReviewal" class="holamundo" @close="initializeAll"
       title="Submit Review" :active.sync="popupAddReview">
        <div class="mt-2">
          <review-form />
        </div>
      </vs-popup>
    </div>
    <is-loading v-if="isLoading" />
  </div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import OrderOverview from "@/components/order/OrderOverview.vue"
import OrderRequirements from "@/components/order/OrderRequirements.vue"
import OrderDeliveredFiles from "@/components/order/OrderDeliveredFiles.vue"
import OrderMessages from "@/components/order/OrderMessages.vue"
import OrderReview from "@/components/order/OrderReview.vue"
import ReviewForm from "@/components/order/components/ReviewForm.vue"
import OrderExtraService from '@/components/order/OrderExtraService.vue'

export default {
  components: {
    IsLoading,
    OrderOverview,
    OrderRequirements,
    OrderDeliveredFiles,
    OrderMessages,
    OrderReview,
    ReviewForm,
    OrderExtraService
  },
  data() {
    return {
      isLoading: true,
      popupAddReview: false
    }
  },
  watch: {
    "$route":"fetchOrder"
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    },
    order(){
       return this.$store.state.orderList.order
    },
    countMessageNotifs() {
        return this.$store.getters['notification/countMessageNotifications'](this.order.id)
    },
    isNotifyReviewal(){
      return this.order.status === 'completed' && !this.order.is_reviewed;
    },
    activeTab(){
      return this.$route.query.tab
        ? +this.$route.query.tab
        : 0;
    },
  },
  methods:{
    confirmMarkCompleted() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: 'Are you sure?',
        text: 'You are about to update this order to completed.' ,
        accept: this.markAsCompleted,
        acceptText: "Proceed",
      })
    },
    markAsCompleted() {
      const formData = new FormData()
      this.$vs.loading({ color: "#444", type: "sound"})
      this.$http.post(`/orders/${this.order.id}/mark-completed`,formData)
      .then(response => {
        this.$vs.loading.close()
        if(response.data.success){
          this.$store.dispatch("orderList/fetchOrder",this.order.id)
          this.popupAddReview = true
        }
      })
    },
    fetchOrder(){
      this.isLoading = true
      this.$store.dispatch("orderList/fetchOrder",this.$route.params.orderId)
      .then((response) => {
        this.isLoading = false
        if(!response.data.success){
          this.$router.push({path: '/error-404'})
        }
      })
    },
    initializeAll(){
      this.popupAddReview = false
    },
  },
  created(){
    this.fetchOrder();
  },
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
.message-note-bell{
    position: absolute !important;
    top: -2px;
    z-index: 999;
    left: 62px !important;
}

@media(min-width:768px){
    .message-note-bell{
        top: 57px;
        left: 27px !important;
    }
}

.con-vs-tabs{
    .con-ul-tabs{
        .vs-icon{
            font-size:1.5rem;
        }
    }
}

</style>
